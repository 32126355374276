import type { FC } from "react";
import type { Link as XpaLink } from "@motain/xpa-proto-files-web/lib/types/link";
import { trackingService } from "@/modules/analytics";
import Link from "next/link";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { arrowIconProps } from "@/xpa-components/search-entity/search-entity-content/arrowIconProps";
import styles from "./LinkWithArrow.module.scss";
import classNames from "classnames";

export const LinkWithArrow: FC<XpaLink & { className?: string }> = (props) => {
  const { trackingEvents, urlPath, name, className } = props;
  return (
    <Link
      onClick={() => {
        trackingService.sendXpaTracking(trackingEvents, EventType.EVENT_CLICK);
      }}
      href={urlPath}
      className={classNames("title-7-medium", styles.container, className)}
    >
      {name}
      <ImageWithFallback
        data-testid="arrow"
        className={styles.image}
        {...arrowIconProps}
      ></ImageWithFallback>
    </Link>
  );
};
