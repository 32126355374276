import { MEDIA_QUERY } from "@/constants/media-query.constant";
import { ImageRatio } from "@/constants/image-ratio.constants";

export const TEASER_SIZE_TO_IMAGE_SOURCE_SETS = {
  xl: {
    desktop: {
      media: MEDIA_QUERY.desktop,
      width: 840,
      ratio: ImageRatio.Wide16to9,
    },
    tabletLandscape: {
      media: MEDIA_QUERY.tabletLandscape,
      width: 620,
      ratio: ImageRatio.Wide16to9,
    },
    tabletPortrait: {
      media: MEDIA_QUERY.tabletPortrait,
      width: 334,
      ratio: ImageRatio.Square,
    },
    mobile: {
      media: MEDIA_QUERY.mobile,
      width: 335,
      ratio: ImageRatio.Wide16to9,
    },
  },
  lg: {
    desktop: {
      media: MEDIA_QUERY.desktop,
      width: 420,
      ratio: ImageRatio.Square,
    },
    tabletLandscape: {
      media: MEDIA_QUERY.tabletLandscape,
      width: 342,
      ratio: ImageRatio.Square,
    },
    tabletPortrait: {
      media: MEDIA_QUERY.tabletPortrait,
      width: 336,
      ratio: ImageRatio.Square,
    },
    mobile: {
      media: MEDIA_QUERY.mobile,
      width: 335,
      ratio: ImageRatio.Wide16to9,
    },
  },
  md: {
    desktop: {
      media: MEDIA_QUERY.desktop,
      width: 290,
      ratio: ImageRatio.Square,
    },
    tabletLandscape: {
      media: MEDIA_QUERY.tabletLandscape,
      width: 217,
      ratio: ImageRatio.Square,
    },
    tabletPortrait: {
      media: MEDIA_QUERY.tabletPortrait,
      width: 336,
      ratio: ImageRatio.Default3to2,
    },
    mobile: {
      media: MEDIA_QUERY.mobile,
      width: 335,
      ratio: ImageRatio.Square,
    },
  },
  sm: {
    desktop: {
      media: MEDIA_QUERY.desktop,
      width: 400,
      ratio: ImageRatio.Default3to2,
    },
    tabletLandscape: {
      media: MEDIA_QUERY.tabletLandscape,
      width: 299,
      ratio: ImageRatio.Default3to2,
    },
    tabletPortrait: {
      media: MEDIA_QUERY.tabletPortrait,
      width: 219,
      ratio: ImageRatio.Default3to2,
    },
    mobile: {
      media: MEDIA_QUERY.mobile,
      width: 335,
      ratio: ImageRatio.Square,
    },
  },
  xs: {
    desktop: {
      media: MEDIA_QUERY.desktop,
      width: 290,
      ratio: ImageRatio.Default3to2,
    },
    tabletLandscape: {
      media: MEDIA_QUERY.tabletLandscape,
      width: 218,
      ratio: ImageRatio.Default3to2,
    },
    tabletPortrait: {
      media: MEDIA_QUERY.tabletPortrait,
      width: 219,
      ratio: ImageRatio.Default3to2,
    },
    mobile: {
      media: MEDIA_QUERY.mobile,
      width: 64,
      ratio: ImageRatio.Square,
    },
  },
} as const;
