import type {
  GalleryExtraTeasers,
  TeaserComponent,
} from "@motain/xpa-proto-files-web/lib/types/gallery";
import type { LoadMoreButton } from "@motain/xpa-proto-files-web/lib/types/load_more_button";
import type { Reader } from "protobufjs";
import { apiFetchWithDecode } from "@/utils/apiFetchWithDecoder";
import { FETCH_METHOD } from "@/constants/fetch-method";

type GalleryExtraTeasersDecoder = (
  input: Uint8Array | Reader,
  length?: number,
) => GalleryExtraTeasers;

const lazyImportGalleryExtraTeasersDecoder =
  async (): Promise<GalleryExtraTeasersDecoder> => {
    const {
      GalleryExtraTeasers: { decode },
    } = await import("@motain/xpa-proto-files-web/lib/runtime/gallery");

    return decode;
  };

export const galleryLoadMoreButtonService = {
  getLoadMoreButton: (
    teasers: Array<TeaserComponent>,
  ): LoadMoreButton | undefined => {
    const lastElement = teasers[teasers.length - 1];
    return lastElement?.loadMoreButton;
  },

  fetchMoreTeasers: async (
    apiUrl: string,
  ): Promise<{
    status: number;
    decoded?: GalleryExtraTeasers;
  }> => {
    const decoder = await lazyImportGalleryExtraTeasersDecoder();

    return apiFetchWithDecode<GalleryExtraTeasers>(apiUrl, {
      method: FETCH_METHOD.GET,
      decoder: decoder,
    });
  },
};
