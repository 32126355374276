import type { FC } from "react";
import { useReducer, useState } from "react";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import {
  BOOKMARK_ICON_FILLED_PROPS,
  BOOKMARK_ICON_SHAPE_PROPS,
} from "./BookmarkImages.const";
import useTranslation from "next-translate/useTranslation";
import { Button } from "@/components/button/Button";
import type { ButtonVariant } from "@/components/button/ButtonVariant";
import { useBookmarkHandler } from "@/components/bookmark-button/useBookmarkHandler";

//Variant "Fill" of button would not work with this component due the image colors
export interface BookmarkButtonProps {
  isBookmarked: boolean;
  id: string;
  variant?: Exclude<ButtonVariant, "fill">;
  className?: string | undefined;
}

const BookmarkButton: FC<NonNullable<BookmarkButtonProps>> = (props) => {
  const { t } = useTranslation("web-payments");
  const { id, variant = "text-button", className } = props;
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [isBookmarked, toggleIsBookmarked] = useReducer(
    (prevState: boolean): boolean => {
      return !prevState;
    },
    props.isBookmarked,
  );
  const { handleBookmark } = useBookmarkHandler({
    isBookmarked,
    bookmarkCallback: toggleIsBookmarked,
    id,
  });

  const handleClick = async () => {
    setIsDisabledButton(true);
    await handleBookmark();
    setIsDisabledButton(false);
  };

  // If id is empty, all the http calls will fail, so we return null
  if (id === "") {
    return null;
  }

  return (
    <Button
      className={className}
      variant={variant}
      disabled={isDisabledButton}
      onClick={() => {
        void handleClick();
      }}
    >
      {isBookmarked ? (
        <>
          <ImageWithFallback {...BOOKMARK_ICON_FILLED_PROPS} />
          <span className="screen-reader-only">{t`UNBOOKMARK_BUTTON`}</span>
        </>
      ) : (
        <>
          <ImageWithFallback {...BOOKMARK_ICON_SHAPE_PROPS} />
          <span className="screen-reader-only">{t`BOOKMARK_BUTTON`}</span>
        </>
      )}
    </Button>
  );
};

export { BookmarkButton };
