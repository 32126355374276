import type { FC } from "react";
import styles from "./LiveArticleIndicator.module.scss";
import classnames from "classnames";
// we usually name types with the -component suffix, we should probably change that
import type { LiveArticleIndicator as LiveArticleIndicatorComponent } from "@motain/xpa-proto-files-web/lib/types/news";

type LiveArticleIndicatorProps = LiveArticleIndicatorComponent & {
  className?: string;
  variant?: "big" | "small";
};

export const LiveArticleIndicator: FC<LiveArticleIndicatorProps> = ({
  text,
  className,
  variant = "big",
}) => {
  const typographyVariant = variant === "big" ? "title-4-bold" : "label";

  return (
    <span
      className={classnames(
        typographyVariant,
        styles.wrapper,
        className,
        variant === "small" && styles.small,
      )}
    >
      <span aria-hidden={true} className={classnames(styles.dot)} />
      {text !== "" && <span className={styles.text}>{text}</span>}
    </span>
  );
};
