import type { TrackingEvent } from "@motain/xpa-proto-files-web/lib/types/tracking";
import {
  EventType,
  ServerParameterType,
  TrackerType,
} from "@motain/xpa-proto-files-web/lib/types/tracking";
import {
  RudderstackEventName,
  TrackingEventName,
  trackingService,
} from "@/modules/analytics";

/**
 * outcome parameters values: article_removed; article_saved and login_presented are string defined into the jira ticket
 * https://onefootball.atlassian.net/jira/software/projects/WEB/boards/182?selectedIssue=WEB-2976
 */

/**
 * Tracks Bookmark or UnBookmark, it should be use only if the user is already logging
 */
export const bookmarkTracking = (isBookmarked: boolean, id: string): void => {
  const bookmarkTrackingEvent: TrackingEvent = {
    type: EventType.EVENT_CLICK,
    name: TrackingEventName.BookmarkClicked,
    clientParameterRequests: [],
    typedServerParameter: {
      article_id: {
        value: id,
        type: ServerParameterType.STRING,
      },
      outcome: {
        value: isBookmarked ? "article_removed" : "article_saved",
        type: ServerParameterType.STRING,
      },
    },
    trackers: [
      {
        type: TrackerType.TRACKER_LOCALYTICS,
        trackerParameters: {},
      },
    ],
    schema: "",
  };

  const bookmarkTrackingEventRudderstack: TrackingEvent = {
    type: EventType.EVENT_CLICK,
    name: RudderstackEventName.BookmarkClicked,
    clientParameterRequests: [],
    typedServerParameter: {
      article_id: {
        value: id,
        type: ServerParameterType.STRING,
      },
      bookmark_outcome: {
        value: isBookmarked ? "article_removed" : "article_saved",
        type: ServerParameterType.STRING,
      },
    },
    trackers: [
      {
        type: TrackerType.TRACKER_RUDDERSTACK,
        trackerParameters: {},
      },
    ],
    schema: "",
  };

  trackingService.sendXpaTracking(
    [bookmarkTrackingEvent, bookmarkTrackingEventRudderstack],
    EventType.EVENT_CLICK,
  );
};

/**
 * Tracks Bookmark redirection to the login page, it should be use only if the user is not logging
 */
export const bookmarkTrackingLogin = (id: string): void => {
  const bookmarkTrackingEvent: TrackingEvent = {
    type: EventType.EVENT_CLICK,
    name: TrackingEventName.BookmarkClicked,
    clientParameterRequests: [],
    typedServerParameter: {
      article_id: {
        value: id,
        type: ServerParameterType.STRING,
      },
      outcome: {
        value: "login_presented",
        type: ServerParameterType.STRING,
      },
    },
    trackers: [
      {
        type: TrackerType.TRACKER_LOCALYTICS,
        trackerParameters: {},
      },
    ],
    schema: "",
  };

  const bookmarkTrackingEventRudderstack: TrackingEvent = {
    type: EventType.EVENT_CLICK,
    name: RudderstackEventName.BookmarkClicked,
    clientParameterRequests: [],
    typedServerParameter: {
      article_id: {
        value: id,
        type: ServerParameterType.STRING,
      },
      bookmark_outcome: {
        value: "login_presented",
        type: ServerParameterType.STRING,
      },
    },
    trackers: [
      {
        type: TrackerType.TRACKER_RUDDERSTACK,
        trackerParameters: {},
      },
    ],
    schema: "",
  };

  trackingService.sendXpaTracking(
    [bookmarkTrackingEvent, bookmarkTrackingEventRudderstack],
    EventType.EVENT_CLICK,
  );
};
