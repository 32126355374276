import type { Link } from "@motain/xpa-proto-files-web/lib/types/link";
import { error } from "@/utils/logger";

export const constructHrefFromXpaLink = (
  link: Link | undefined,
): string | undefined => {
  if (link === undefined) {
    return undefined;
  }

  try {
    const url = new URL(link.urlPath);
    const linkParams = Object.entries(link.queryParams);

    linkParams.forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });

    return url.href;
  } catch (e: unknown) {
    const errorMessage = "Couldn't construct href from XPA Link.";

    if (e instanceof Error) {
      error(errorMessage, e);
    } else {
      error(errorMessage);
    }

    return undefined;
  }
};
