import type { Result } from "@/utils/result";
import { err, tryToResult } from "@/utils/result";
import type { TeaserGridSizes } from "@motain/xpa-proto-files-web/lib/types/gallery";
import type { ImageSourceSetsRecord } from "@/types/of-image.types";
import { getRecordEntries } from "@/utils/getRecordEntries";
import { DEFAULT_SOURCE_SETS } from "@/constants/of-image.constant";
import { parseTeaserSize } from "./parseTeaserSize";
import { TEASER_SIZE_TO_IMAGE_SOURCE_SETS } from "./teaser.constants";
import { assertNonNullable } from "@/utils/assertNonNullable";
import { imageUrlService } from "@/services/image-url-service";
import type { ImageObject } from "@motain/xpa-proto-files-web/lib/types/image";
import { isNullable } from "@/types/isNullable";

export const buildTeaserImageSourceSets = (
  gridSizes: TeaserGridSizes | undefined,
  imageObject: ImageObject | undefined,
): Result<ImageSourceSetsRecord> => {
  if (isNullable(gridSizes)) {
    return err("Teaser did not contain gridSizes");
  }

  return tryToResult(() => {
    return getRecordEntries(DEFAULT_SOURCE_SETS).reduce(
      (record, [mediaQueryType]) => {
        const teaserSize = parseTeaserSize(gridSizes[mediaQueryType]).unwrap();

        const set =
          TEASER_SIZE_TO_IMAGE_SOURCE_SETS[teaserSize][mediaQueryType];

        const imageObjectPath = assertNonNullable(imageObject).path;

        const url = imageUrlService
          .buildImgSrc({
            ...set,
            src: imageObjectPath,
          })
          .unwrap();

        record[mediaQueryType] = {
          ...set,
          lightModeUrl: url,
          darkModeUrl: url,
        };

        return record;
      },
      {
        mobile: {
          ...DEFAULT_SOURCE_SETS.mobile,
          lightModeUrl: "",
          darkModeUrl: "",
        },
        tabletPortrait: {
          ...DEFAULT_SOURCE_SETS.tabletPortrait,
          lightModeUrl: "",
          darkModeUrl: "",
        },
        tabletLandscape: {
          ...DEFAULT_SOURCE_SETS.tabletLandscape,
          lightModeUrl: "",
          darkModeUrl: "",
        },
        desktop: {
          ...DEFAULT_SOURCE_SETS.desktop,
          lightModeUrl: "",
          darkModeUrl: "",
        },
      },
    );
  });
};
