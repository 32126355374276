import type { FC } from "react";
import { useCallback, useContext, useState } from "react";
import classNames from "classnames";
import type { GalleryComponent } from "@motain/xpa-proto-files-web/lib/types/gallery";
import styles from "./Gallery.module.scss";
import { teaserSizeClassNames } from "@/xpa-components/news-teaser/teaser-size-class-names";
import { SectionHeader } from "@/xpa-components/section-header/SectionHeader";
import { constructHrefFromXpaLink } from "@/utils/constructHrefFromXpaLink";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { NewsTeaser } from "@/xpa-components/news-teaser/NewsTeaser";
import { isNonNullable } from "@/types/isNonNullable";
import { Button } from "@/components/button/Button";
import { remoteLogger } from "@/utils/remoteLogger";
import { galleryLoadMoreButtonService } from "@/xpa-components/gallery/gallery-load-more-button-service";
import useTranslation from "next-translate/useTranslation";
import { SnackbarContext } from "@/components/snackbar/Snackbar.context";
import { SeverityType } from "@/components/snackbar/Snackbar";
import { LinkWithArrow } from "@/components/link-with-arrow/LinkWithArrow";

enum GalleryStateType {
  LoadMoreAvailable = "LoadMoreAvailable",
  LoadMoreNotAvailable = "LoadMoreNotAvailable",
  NoMoreNews = "NoMoreNews",
}

export const Gallery: FC<GalleryComponent> = (props) => {
  const { t } = useTranslation("web-payments");
  const { sectionHeader, teasers: teasersProps, link } = props;
  const [teasers, setTeasers] = useState(teasersProps);
  const [isLoading, setIsLoading] = useState(false);
  const showNotification = useContext(SnackbarContext);

  const loadMoreButton =
    galleryLoadMoreButtonService.getLoadMoreButton(teasers);
  const seeMoreUrl = constructHrefFromXpaLink(sectionHeader?.seeMore);

  const [galleryState, setGalleryState] = useState<GalleryStateType>(
    isNonNullable(loadMoreButton)
      ? GalleryStateType.LoadMoreAvailable
      : GalleryStateType.LoadMoreNotAvailable,
  );

  const sendSeeMoreTracking = useCallback(() => {
    if (sectionHeader?.seeMore?.trackingEvents !== undefined) {
      trackingService.sendXpaTracking(
        sectionHeader.seeMore.trackingEvents,
        EventType.EVENT_CLICK,
      );
    }
  }, [sectionHeader]);

  const handleLoadMoreButtonClick = useCallback(() => {
    if (isNonNullable(loadMoreButton)) {
      trackingService.sendXpaTracking(
        loadMoreButton.trackingEvents,
        EventType.EVENT_CLICK,
      );

      setIsLoading(true);

      galleryLoadMoreButtonService
        .fetchMoreTeasers(loadMoreButton.apiUrl)
        .then((response) => {
          if (
            isNonNullable(response.decoded) &&
            response.decoded.teasers.length > 0
          ) {
            const newTeasers = [...teasers, ...response.decoded.teasers];
            setTeasers(newTeasers);
          } else {
            setGalleryState(GalleryStateType.NoMoreNews);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);

          showNotification?.({
            type: SeverityType.error,
            message: t`GENERIC_ERROR_MESSAGE`,
          });

          remoteLogger.error(error);
        });
    }
  }, [loadMoreButton, showNotification, t, teasers]);

  return (
    <section className={classNames(styles.grid, styles.gallery__grid)}>
      {sectionHeader && (
        <div className={styles.gallery__headerWrapper}>
          <SectionHeader {...sectionHeader} />
          {sectionHeader.seeMore && (
            <a
              className={classNames(
                styles.gallery__seeMoreButton,
                "title-7-bold",
              )}
              href={seeMoreUrl}
              onClick={sendSeeMoreTracking}
            >
              {sectionHeader.seeMore.name}
            </a>
          )}
        </div>
      )}
      <ul className={styles.galleryItems}>
        {teasers.map((itemTeaser) => {
          const galleryTeaserClassName = teaserSizeClassNames(
            "gallery__teaser",
            itemTeaser.gridSizes,
          );

          const className = galleryTeaserClassName
            .map((item) => {
              return styles[item];
            })
            .join(" ");

          return (
            <li key={itemTeaser.uiKey} className={className}>
              <NewsTeaser {...itemTeaser} />
            </li>
          );
        })}
      </ul>
      {galleryState === GalleryStateType.LoadMoreAvailable &&
        isNonNullable(loadMoreButton) && (
          <Button
            disabled={isLoading}
            onClick={handleLoadMoreButtonClick}
            className={styles.loadMoreButton}
          >
            {loadMoreButton.name}
          </Button>
        )}
      {galleryState === GalleryStateType.NoMoreNews && (
        <p
          className={classNames("title-6-regular", styles.loadMoreButton)}
        >{t`GALLERY_NO_MORE_NEWS`}</p>
      )}
      {isNonNullable(link) && <LinkWithArrow {...link} />}
    </section>
  );
};
