import type { Immutable } from "@/types/immutable";
import type { TeaserComponent } from "@motain/xpa-proto-files-web/lib/types/gallery";
import { isNullable } from "@/types/isNullable";

export const teaserSizeClassNames = (
  elementClassName: string,
  gridSizes: Immutable<TeaserComponent>["gridSizes"],
): Array<string> => {
  if (isNullable(gridSizes)) {
    return [elementClassName];
  }

  return [
    elementClassName,
    `${elementClassName}--desktop--${gridSizes.desktop}`,
    `${elementClassName}--tablet-landscape--${gridSizes.tabletLandscape}`,
    `${elementClassName}--tablet-portrait--${gridSizes.tabletPortrait}`,
    `${elementClassName}--mobile--${gridSizes.mobile}`,
  ];
};
