import type { FC } from "react";
import React from "react";
import type { TeaserComponent } from "@motain/xpa-proto-files-web/lib/types/gallery";
import styles from "./NewsTeaser.module.scss";
import { trackingService } from "@/modules/analytics";
import { EventType } from "@motain/xpa-proto-files-web/lib/types/tracking";
import { isNonNullable } from "@/types/isNonNullable";
import { ImageWithFallback } from "@/components/image/ImageWithFallback";
import { buildTeaserImageSourceSets } from "./teaser-image-service";
import { error } from "@/utils/logger";
import { ImageWithSets } from "@/components/image-with-sets/ImageWithSets";
import { teaserSizeClassNames } from "./teaser-size-class-names";
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import { BookmarkButton } from "@/components/bookmark-button/BookmarkButton";
import Link from "next/link";
import { LiveArticleIndicator } from "@/xpa-components/article-hero-banner/live-article-indicator/LiveArticleIndicator";

const TEASER_IMAGE_CSS_CLASS_NAME = "teaser__img";
const TEASER_IMAGE_VIDEO_CSS_CLASS_NAME = "teaser__img--video";

export const NewsTeaser: FC<TeaserComponent> = (props) => {
  const { t } = useTranslation("web-payments");

  const {
    trackingEvents,
    gridSizes,
    imageObject,
    publishTime,
    publisherName,
    link,
    duration,
    isVideo,
    preview,
    title,
    publishTimestamp,
    publisherImageObject,
    publisherIsVerified,
    id,
    isBookmarked,
    liveArticleIndicator,
  } = props;

  const imageSourceSets = buildTeaserImageSourceSets(gridSizes, imageObject);

  if (!imageSourceSets.isOk()) {
    error(imageSourceSets.error.message, imageSourceSets.error);
  }

  const teaserClassName = teaserSizeClassNames("teaser", gridSizes)
    .map((item) => styles[item])
    .join(" ");

  const showDivider =
    (isNonNullable(publisherName) && publisherName !== "") ||
    publisherIsVerified;

  /**
   * WARNING HACK: the image has to be a clickable area, so it is an anchor that is why it has to contain
   *     aria-hidden="true" rel="null" tabIndex={-1}
   *     to avoid producing accessibility issues, or SEO problems.
   *
   * WARNING HACK: the footer has to be a clickable area, in the future it could contain the url to the provider
   *    but at the moment is takes the user to the same page as the normal article, that is why it has to contain:
   *    aria-hidden="true" rel="null" tabIndex={-1}
   *    to avoid producing accessibility issues, or SEO problems.
   */
  return (
    <article className={classNames(styles.teaser, teaserClassName)}>
      <Link
        data-testid="image-link"
        className={classNames(
          styles.teaser__imageWrapper,
          isNonNullable(liveArticleIndicator) && styles.liveImageMargin,
        )}
        aria-hidden="true"
        rel="null"
        tabIndex={-1}
        href={link}
        onClick={() => {
          trackingService.sendXpaTracking(
            trackingEvents,
            EventType.EVENT_CLICK,
          );
        }}
      >
        {isNonNullable(imageObject) && (
          <ImageWithSets
            classNames={classNames(
              isNonNullable(duration) && TEASER_IMAGE_VIDEO_CSS_CLASS_NAME,
              TEASER_IMAGE_CSS_CLASS_NAME,
            )}
            alt={imageObject.alt}
            image={imageSourceSets.isOk() ? imageSourceSets.value : undefined}
            isPreloaded={imageObject.isPreloaded}
          />
        )}

        {isVideo && (
          <>
            <ImageWithFallback
              data-testid="video-icon"
              className={styles.teaser__playVideoIcon}
              width={50}
              height={50}
              alt={""}
              src={"https://images.onefootball.com/cw/icons/play_icon.svg"}
            />

            <span
              className={classNames(
                "title-8-medium",
                styles.teaser__videoDuration,
              )}
            >
              {isNonNullable(duration) ? duration.formatted : ""}
            </span>
          </>
        )}
      </Link>

      <Link
        href={link}
        className={styles.teaser__content}
        onClick={() => {
          trackingService.sendXpaTracking(
            trackingEvents,
            EventType.EVENT_CLICK,
          );
        }}
      >
        <p className={styles.teaser__title}>
          {isNonNullable(liveArticleIndicator) ? (
            <>
              <LiveArticleIndicator
                {...liveArticleIndicator}
                className={styles.liveIndicator}
                variant="small"
              />
              <span className={styles.liveTitle}>{title}</span>
            </>
          ) : (
            title
          )}
        </p>

        {isNonNullable(preview) && (
          <p className={styles.teaser__preview}>{preview}</p>
        )}
      </Link>

      {isNonNullable(publisherName) && (
        <footer className={styles.teaser__footer}>
          <Link
            data-testid="footer-link"
            className={styles.teaser__footerAnchor}
            aria-hidden="true"
            rel="null"
            tabIndex={-1}
            href={link}
            onClick={() => {
              trackingService.sendXpaTracking(
                trackingEvents,
                EventType.EVENT_CLICK,
              );
            }}
          >
            {isNonNullable(publisherImageObject) && (
              <div className={styles.teaser__publisherNameLogo}>
                <ImageWithFallback
                  fallbackImageSrc="https://images.onefootball.com/cw/icons/entity-logo-placeholder.svg"
                  width={24}
                  height={24}
                  alt={publisherImageObject.alt}
                  src={publisherImageObject.path}
                  darkModeSrc={publisherImageObject.darkModePath}
                />
              </div>
            )}
            {isNonNullable(publisherName) && publisherName !== "" && (
              <span
                className={classNames(
                  "title-8-regular",
                  styles.teaser__publisherName,
                )}
              >
                {publisherName}
              </span>
            )}

            {publisherIsVerified && (
              <ImageWithFallback
                className={styles.teaser__publisherNameIcon}
                width={24}
                height={24}
                src={
                  "https://images.onefootball.com/cw/icons/verified-icon-light.svg"
                }
                darkModeSrc={
                  "https://images.onefootball.com/cw/icons/verified-icon-dark.svg"
                }
                alt={t`VERIFY_ICON_ALT`}
              />
            )}

            {isNonNullable(publishTimestamp.length) && (
              <>
                {showDivider && <>&middot;</>}
                <time
                  className={classNames(
                    "title-8-regular",
                    styles.publisherTime,
                  )}
                  dateTime={publishTimestamp}
                >
                  {publishTime}
                </time>
              </>
            )}
          </Link>
        </footer>
      )}
      {!isVideo && isNonNullable(id) && (
        <BookmarkButton
          className={styles.bookmarkButton}
          isBookmarked={isBookmarked}
          id={id}
        />
      )}
    </article>
  );
};
