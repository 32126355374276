import type { ImageWithFallbackProps } from "@/components/image/ImageWithFallback";

const BOOKMARK_FILLED_DARK =
  "https://images.onefootball.com/cw/icons/bookmark-dark.svg";
const BOOKMARK_FILLED_LIGHT =
  "https://images.onefootball.com/cw/icons/bookmark-light.svg";
const BOOKMARK_SHAPE_LIGHT =
  "https://images.onefootball.com/cw/icons/bookmark-shape-light-v2.svg";
const BOOKMARK_SHAPE_DARK =
  "https://images.onefootball.com/cw/icons/bookmark-shape-dark-v2.svg";

export const BOOKMARK_ICON_FILLED_PROPS: ImageWithFallbackProps = {
  src: BOOKMARK_FILLED_LIGHT,
  alt: "",
  darkModeSrc: BOOKMARK_FILLED_DARK,
  width: 24,
  height: 24,
  isPreloaded: false,
};

export const BOOKMARK_ICON_SHAPE_PROPS: ImageWithFallbackProps = {
  src: BOOKMARK_SHAPE_LIGHT,
  alt: "",
  darkModeSrc: BOOKMARK_SHAPE_DARK,
  width: 24,
  height: 24,
  isPreloaded: false,
};
