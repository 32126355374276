import type { Result } from "@/utils/result";
import { XpaTeaserSize } from "@/enums/xpa-teaser-size";
import { err, ok } from "@/utils/result";

export const parseTeaserSize = (value: string): Result<XpaTeaserSize> => {
  const normalized = value.charAt(0).toUpperCase() + value.slice(1);

  switch (normalized) {
    case "Xs":
    case "Sm":
    case "Md":
    case "Lg":
    case "Xl":
      return ok(XpaTeaserSize[normalized]);
    default:
      return err(Error("Could not create ImageSourceSetsRecord"));
  }
};
