import { useAuthentication } from "@/context/Authentication";
import { SearchParam } from "@/enums/search-param.enum";
import { PROFILE_SIGN_UP_ROUTE } from "@/constants/routes.constant";
import {
  bookmarkTracking,
  bookmarkTrackingLogin,
} from "@/components/bookmark-button/BookmarkTracking.const";
import { BOOKMARK_ENDPOINT } from "@/constants/endpoints.constant";
import { webExperienceFetch } from "@/utils/webExperienceFetch";
import { FETCH_METHOD } from "@/constants/fetch-method";
import { isValidHTTPStatusCode } from "@/utils/isValidHTTPStatusCode";
import { SeverityType } from "@/components/snackbar/Snackbar";
import { remoteLogger } from "@/utils/remoteLogger";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { useContext } from "react";
import { SnackbarContext } from "@/components/snackbar/Snackbar.context";

interface BookmarkHandler {
  isBookmarked: boolean;
  bookmarkCallback: () => void;
  id: string;
}

type useBookmarkHandlerType = (props: BookmarkHandler) => {
  handleBookmark: () => Promise<void>;
};

export const useBookmarkHandler: useBookmarkHandlerType = (props) => {
  const { isBookmarked, bookmarkCallback, id } = props;

  const { t } = useTranslation("web-payments");
  const router = useRouter();
  //This element has a login wall, so if the user clicks and is not logged it has to be redirected to the login-page
  const { authState } = useAuthentication();
  const showNotification = useContext(SnackbarContext);

  const handleBookmark = async () => {
    if (authState.kind === "unauthenticated") {
      const urlSearchParams = new URLSearchParams({
        [SearchParam.Redirect]: router.asPath,
        [SearchParam.Origin]: "bookmark-button",
      });
      const redirectUrl = `${PROFILE_SIGN_UP_ROUTE}?${urlSearchParams.toString()}`;
      bookmarkTrackingLogin(id);
      await router.push(redirectUrl);
      return;
    }

    const bookmarkEndpointUrl = `${BOOKMARK_ENDPOINT}/${id}`;

    try {
      const response = await webExperienceFetch(bookmarkEndpointUrl, {
        method: isBookmarked ? FETCH_METHOD.DELETE : FETCH_METHOD.POST,
      });

      if (isValidHTTPStatusCode(response.status)) {
        bookmarkTracking(isBookmarked, id);

        showNotification?.({
          type: SeverityType.success,
          message: isBookmarked ? t`UNBOOKMARK_SUCCESS` : t`BOOKMARK_SUCCESS`,
        });

        bookmarkCallback();
      } else {
        const errorMessage = `Fail to ${
          isBookmarked ? "BOOKMARK" : "UNBOOKMARK"
        } with an error status of ${response.status} in the url`;

        remoteLogger.error(errorMessage);

        showNotification?.({
          type: SeverityType.error,
          message: t`GENERIC_ERROR_MESSAGE`,
        });
      }
    } catch (error) {
      const errorMessage = `Fail to ${
        isBookmarked ? "BOOKMARK" : "UNBOOKMARK"
      } the element`;

      remoteLogger.error(errorMessage);

      showNotification?.({
        type: SeverityType.error,
        message: t`GENERIC_ERROR_MESSAGE`,
      });

      remoteLogger.error(error);
    }
  };

  return { handleBookmark };
};
